import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

const Hiit2 = () =>(
    <section className="hiit-2">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Zrób ten 10-minutowy trening, by wrócić do formy i spalić tkankę tłuszczową</h2>
                    <p>Twoje środowisko jest podzielone: część sobie odpuściła, część wylewa siódme poty na siłowni. Też zależy Ci na efektach, ale szukasz czegoś… mniej angażującego? Z tym krótkim treningiem możesz osiągnąć takie same rezultaty, jak regularni bywalcy klubów fitness.</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Hiit2;
