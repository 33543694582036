import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

const Hiit1 = (props) => (
        <section className="hiit-1" id="hiit">
            <Container>
                <Row>
                    <Col xs={{size: 12, offset: 0}} md={{size: 10, offset: 1}}>
                        <h2>Najszybszy sposób na powrót do formy dla mężczyzny po trzydziestce</h2>
                        <p>Powiedzmy sobie szczerze: po trzydziestce organizm zmienia się. Jeszcze kilka lat temu byłeś u szczytu sił, praktycznie niezniszczalny, a zarwane noce uchodziły Ci na sucho.</p>
                        <p>Teraz coraz częściej zauważasz, że Twoje ciało wolniej się regeneruje, metabolizm zmniejszył obroty, a oponka na brzuchu zaczyna się powiększać…</p>
                        <p>Szukasz prostego sposobu, by wrócić do formy i utrzymać produkcję testosteronu na wysokim poziomie? Masz go przed sobą!</p>
    
                        <div className="text-center mt-5">
                            <a href={props.url} className="btn btn-primary">Pobierz aplikację</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
);

export default Hiit1;
