import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import gym from "../../images/hiit/gym.svg";
import muscle from "../../images/hiit/muscle.svg";
import profit from "../../images/hiit/profit.svg";

const Hiit6 = (props) =>(
    <section className="hiit-6">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Dodatkowe zalety HIIT:</h2>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}}  md={{size: 1, offset: 1}}>
                    <img src={muscle} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>SPALASZ & BUDUJESZ</strong><br/>
                    Tu spalanie tkanki tłuszczowej i mięśniowej nie idzie ze sobą w parze. Struktura treningu zaprojektowana jest tak, żeby maksymalnie podkręcić spalanie tkanki tłuszczowej i jednocześnie pozwolić Ci rozbudować mięśnie.</p>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}} md={{size: 1, offset: 1}}>
                    <img src={profit} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>TESTOSTERON IDZIE W GÓRĘ</strong><br/>
                    Badania dowodzą, że taka forma treningu pozytywnie wpływa na produkcję najważniejszego hormonu w ciele mężczyzny, czyli testosteronu.</p>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}} md={{size: 1, offset: 1}}>
                    <img src={gym} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>NIE POTRZEBUJESZ SIŁOWNI</strong><br/>
                    To Ty ustalasz grafik treningowy! Chcesz ćwiczyć w domu, w parku lub na wakacjach? Z naszą aplikacją możesz to zrobić nawet bez dostępu do internetu!</p>
                </Col>
            </Row>

            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <p>Sam widzisz, że trening nie musi być długi, skomplikowany, ani wycieńczający, żeby zapewnić Ci wymarzone efekty - ważniejszy jest dobrze przemyślany plan. To właśnie dzięki niemu wrócisz do formy, pozbędziesz się tkanki tłuszczowej i wyrobisz mięśnie</p>

                    <div className="line mt-5 d-none d-md-block">
                        <span className="d-inline">
                            <a href={props.url} className="btn btn-primary">Pobierz aplikację</a>
                        </span>
                    </div>

                    <a href={props.url} className="btn btn-primary d-md-none mt-5">Pobierz aplikację</a>
                </Col>
            </Row>
                          
        </Container>
    </section>
);

export default Hiit6;
