import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import signet from "../../images/icons/sygnet.svg";
import starFull from "../../images/icons/star_full.svg";
import starHalf from "../../images/icons/star_half.svg";

export class AppBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showAppBar: true };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            showAppBar: !prevState.showAppBar,
        }));
    }

    render() {
        return (
            <>
                <div className={this.state.showAppBar ? 'push' : 'push hide'} />
                <section className={this.state.showAppBar ? 'app-bar' : 'app-bar hide'}>
                    <Container fluid={true}>
                        <Row className="align-items-center">
                            <Col xs="9" md="9" className="pr-0">
                                <button
                                    type="button"
                                    className="close"
                                    onClick={this.toggle}
                                >
                                    &times;
                                </button>
                                <img src={signet} alt="Diet & Training by Ann" className="app-icon" />
                                <span className="app-title">Diet & Training by Ann</span>
                                <div className="stars">
                                    <img src={starFull} alt="" />
                                    <img src={starFull} alt="" />
                                    <img src={starFull} alt="" />
                                    <img src={starFull} alt="" />
                                    <img src={starHalf} alt="" />
                                </div>
                                <span className="for-free">ZA DARMO dla Twojego telefonu</span>
                            </Col>
                            <Col xs="3" md="3" className="pl-0">
                                <a href={this.props.url} className="btn btn-outline-blue float-right">Pobierz<span className="d-none d-md-inline"> aplikację</span></a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

export default AppBar;
