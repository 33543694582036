import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

const Hiit4 = (props) =>(
    <section className="hiit-4">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2 className="line"><span>Tyle kalorii spalisz podczas<br/> 10-minutowego treningu</span></h2>

                    <div className="chart">
                        <ScrollAnimation
                            animateIn="show"
                            animateOnce
                        >
                            <Row className="row-bar">
                                <Col xs={{size: 6, order: 1}} md={{size: 3, order: 1}} className="text-md-right">HIIT</Col>
                                <Col xs={{size: 12, order: 3}} md={{size: 6, order: 2}}>
                                    <div className="bar"></div>
                                </Col>
                                <Col xs={{size: 6, order: 2}} md={{size: 3, order: 2}} className="text-right text-md-left">162 kcal</Col>
                            </Row>

                            <Row className="row-bar">
                                <Col xs={{size: 6, order: 1}} md={{size: 3, order: 1}} className="text-md-right">Bieganie</Col>
                                <Col xs={{size: 12, order: 3}} md={{size: 6, order: 2}}>
                                    <div className="bar"></div>
                                </Col>
                                <Col xs={{size: 6, order: 2}} md={{size: 3, order: 2}} className="text-right text-md-left">90 kcal</Col>
                            </Row>

                            <Row className="row-bar">
                                <Col xs={{size: 6, order: 1}} md={{size: 3, order: 1}} className="text-md-right">Spinning</Col>
                                <Col xs={{size: 12, order: 3}} md={{size: 6, order: 2}}>
                                    <div className="bar"></div>
                                </Col>
                                <Col xs={{size: 6, order: 2}} md={{size: 3, order: 2}} className="text-right text-md-left">80 kcal</Col>
                            </Row>
                        </ScrollAnimation>
                    </div>

                    <div className="text-center mt-5">
                        <a href={props.url} className="btn btn-primary">Pobierz aplikację</a>
                    </div>

                </Col>
            </Row>                
        </Container>
    </section>
);

export default Hiit4;
