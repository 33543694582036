import React from 'react';
import { Container } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import triangle from "../../images/pyramid/triangle.svg";
import scrollToElement from '../../helpers/scrollToElement';

const Hero = (props) => (
    <section className="hero">
        <Container>
            <GatsbyImage image={props.hero} />
            <div
                className="round-button"
                onClick={() =>
                    scrollToElement(
                        document.getElementById('hiit')
                    )
                }
                aria-hidden="true" 
            >
                <img src={triangle} alt="" />
            </div>
        </Container>
    </section>
);
    
export default Hero;
