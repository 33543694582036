import React  from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from 'reactstrap';

const Hiit5 = (props) =>(
    <section className="hiit-5">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Dlaczego akurat ten trening jest taki skuteczny?</h2>
                    <p>HIIT (ang. High Intensity Interval Training – intensywny trening interwałowy) – strategia treningowa, gdzie bardzo intensywne, ale proste technicznie ćwiczenia przeplatają się z takimi o umiarkowanym wysiłku fizycznym.</p>
                    <p>Taki trening daje szybsze efekty niż inne popularne treningi (np. cardio) a jednocześnie jest znacznie krótszy - trwa tylko kilkanaście minut!</p>

                    <GatsbyImage image={props.chart} />
                </Col>
            </Row>
        </Container>
    </section>
);

export default Hiit5;
