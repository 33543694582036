import React, { Component }  from 'react';
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";
import Seo from "../components/seo";
import AppBar from "../components/Hiit/AppBar";
import Hero from "../components/Hiit/Hero";
import Hiit1 from "../components/Hiit/Hiit1";
import Hiit2 from "../components/Hiit/Hiit2";
import Hiit3 from "../components/Hiit/Hiit3";
import Hiit4 from "../components/Hiit/Hiit4";
import Hiit5 from "../components/Hiit/Hiit5";
import Hiit6 from "../components/Hiit/Hiit6";
import Hiit7 from "../components/Hiit/Hiit7";
import Hiit8 from "../components/Hiit/Hiit8";
import Footer from "../components/Hiit/Footer";
import 'url-search-params-polyfill';
import { graphql } from "gatsby";

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = { url: ''};
    }

    async componentDidMount(){
        this.buildURLfromQueryString();
    }

    buildURLfromQueryString(){
        const urlParams = new URLSearchParams(global.location.search);    
        const utm_campaign = urlParams.has('utm_campaign') ? `campaign=${encodeURIComponent(urlParams.get('utm_campaign'))}` : "";
        const utm_term = urlParams.has('utm_term') ? `&adgroup=${encodeURIComponent(urlParams.get('utm_term'))}` : "";
        const install_callback = urlParams.has('install_callback') ? `&install_callback=${encodeURIComponent(urlParams.get('install_callback'))}` : "";
        const click_callback = urlParams.has('click_callback') ? `&click_callback=${encodeURIComponent(urlParams.get('click_callback'))}` : "";
        const event_callback_fn1saf = urlParams.has('event_callback_fn1saf') ? `&event_callback_fn1saf=${encodeURIComponent(urlParams.get('event_callback_fn1saf'))}` : "";
        
        const PYRAMID_RICH_URL = `https://app.adjust.com/jp0rrqi?${utm_campaign}${utm_term}${install_callback}${click_callback}${event_callback_fn1saf}`;

        this.setState({url: PYRAMID_RICH_URL});
    }

    render(){
        return (
            <Layout page="hiit" showMenu={false} showFooter={false}>
                <Seo
                    title="HIIT"
                >
                    <meta name="robots" content="noindex,nofollow" />
                </Seo>
                <AppBar
                    url={this.state.url}
                />
                <Hero hero={this.props.data.hero.childImageSharp.gatsbyImageData} />
                <Hiit1 url={this.state.url} />
                <Hiit2 />
                <Hiit3 />
                <Hiit4 url={this.state.url} />
                <Hiit5 chart={this.props.data.chart.childImageSharp.gatsbyImageData} />
                <Hiit6 url={this.state.url} />
                <Hiit7
                    face={this.props.data.face.childImageSharp.gatsbyImageData}
                />
                <Hiit8
                    phones={this.props.data.phones.childImageSharp.gatsbyImageData}
                    url={this.state.url}
                />
                <Footer />
            </Layout>
        );    
    }
}

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "hiit/hero.png" }) {
            ...fluidImage960
        }

        chart: file(relativePath: { eq: "hiit/chart.png" }) {
            ...fluidImage960
        }

        face: file(relativePath: { eq: "hiit/face.png" }) {
            ...fixedImage100
        }

        phones: file(relativePath: { eq: "hiit/phone.png" }) {
            ...fluidImage960
        }
    }
`;
