import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";

const Hiit7 = (props) => (
    <section className="hiit-7">
        <Container>
            <Row >
                <Col md={{size: 8, offset: 2}} className="text-center">
                    <GatsbyImage image={props.face} />
                    <p><strong>„Znajomi z kpiącym uśmieszkiem na twarzy słuchali,<br className="d-none d-nd-block"/>kiedy mówiłem im jak ćwiczę z aplikacją Ani Lewandowskiej…</strong></p>
                    <p>…że niby to tylko dla kobiet. Zorganizowałem więc dla kumpli wspólny trening z&nbsp;apką - zrobiliśmy ten, gdzie do współpracy Ania zaprosiła Kubę ze swojego Healthy Teamu. Była moc! ”</p>
                    <p className="blue">- Jarek</p>
                </Col>    
            </Row>
        </Container>
    </section>
);
    
export default Hiit7;
