import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import training1 from "../../images/hiit/hiit2.gif";
import training2 from "../../images/hiit/hiit1.gif";
import process from "../../images/hiit/process.svg";
import fire from "../../images/pyramid/fire.svg";

const Hiit3 = () =>(
    <section className="hiit-3">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2 className="line "><span>HIIT</span></h2>
                </Col>
            </Row>

            <Row>
                <Col xs={{size: 6, offset: 0}} md={{size: 4, offset: 2}}>
                    <img src={training1} className="img-fluid" alt="" />
                </Col>
                <Col xs={{size: 6, offset: 0}} md="4">
                    <img src={training2} className="img-fluid" alt="" />
                </Col>
                <Col md={{size: 8, offset: 2}}>
                    <p className="mt-4 text-center">Powtarzaj te ćwiczenia zgodnie z poniższym schematem</p>
                    <img src={process} className="img-fluid my-4" alt="" />
                </Col>
            </Row>
    
            <Row>
                <Col md={{size: 10, offset: 1}} className="text-center">
                    
                    
                    <div className="success">
                        <Row className="align-items-center">
                            <Col xs="3" md="2"><img src={fire} className="img-fluid" alt="" /></Col>
                            <Col xs="9" md="10">Sukces! Właśnie spaliłeś <strong>162 kalorie</strong> w 10 minut!</Col>
                        </Row>
                    </div>
                </Col>
            </Row>  
                
        </Container>
    </section>
);

export default Hiit3;
